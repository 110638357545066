import React, { useState, useEffect } from "react"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import styled from "styled-components"

import Flex from "components/atoms/Flex"
import Button from "components/atoms/Button"

import CloseIcon from "components/icons/white-close-x-icon.svg"

const StyledDiv = styled(Flex)`
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  @media (max-width: 640px) {
    iframe {
      width: 90vw !important;
      height: 50.625vw !important;
    }
  }
  @media (min-width: 641px) and (max-width: 1023px) {
    iframe {
      width: 80vw !important;
      height: 45vw !important;
    }
  }
  @media (min-width: 1024px) {
    iframe {
      width: 75vw !important;
      height: 42.1875vw !important;
    }
  }
`

const VideoDialog = ({ show, close, html, foxBusinessVideoId }) => {
  const [iframeEmbed, setIframeEmbed] = useState(null)

  useEffect(() => {
    if (html) {
      setIframeEmbed(html.html)
    } else if (foxBusinessVideoId) {
      setIframeEmbed(
        `<iframe aria-label="More+Videos" src="//video.foxbusiness.com/v/video-embed.html?video_id=${foxBusinessVideoId}" width="466" height="263" marginwidth="0" marginheight="0" frameborder="0" scrolling="no"></iframe>`
      )
    } else {
      setIframeEmbed(null)
    }
  }, [])

  if (!iframeEmbed) {
    return null
  }

  return (
    <DialogOverlay
      style={{
        background: "rgba(28, 39, 77, .9)",
        zIndex: 1000,
      }}
      isOpen={show}
      onDismiss={close}
      onClick={close}
    >
      <DialogContent
        style={{
          zIndex: 1000,
          background: "transparent",
          padding: 0,
          margin: 0,
          width: "100vw",
        }}
        onClick={close}
      >
        {iframeEmbed && show && (
          <Flex
            height={"100vh"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Flex
              width={{ _: "90vw", sm: "80vw", lg: "75vw" }}
              justifyContent={"flex-end"}
            >
              <Button
                variant={"filled"}
                bg={"transparent"}
                onClick={close}
                mb={3}
              >
                <CloseIcon />
              </Button>
            </Flex>
            <StyledDiv dangerouslySetInnerHTML={{ __html: iframeEmbed }} />
          </Flex>
        )}
      </DialogContent>
    </DialogOverlay>
  )
}

export default VideoDialog
