import React, { cloneElement, useContext } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { ThemeContext } from "styled-components"

import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"

/**
 * Grid component using Flexbox
 *
 * Columns: number of columns at different widths
 * Gutter: the spacing between items
 * Align: justify items left or center
 */
const FlexGrid = ({
  children,
  columns,
  gutter,
  align,
  numColumns,
  displayChild = null,
  ...rest
}) => {
  if (numColumns > 0) {
    switch (numColumns) {
      case 1:
        columns = 1
        break
      case 2:
        columns = { _: 1, md: 2 }
        break
      case 3:
        columns = { _: 1, md: 3 }
        break
      case 4:
        columns = { _: 1, md: 2, lg: 4 }
        break
      case 5:
        columns = { _: 1, md: 3, lg: 5 }
        break
      default:
        columns = { _: 1, md: 3, lg: numColumns }
    }
  }

  // Need to get the space object from theme
  // so we can divde the spacing by 2 to get the correct spacing bw items
  let { space } = useContext(ThemeContext)

  let styles = {}
  // Spacing based on gutter
  if (typeof gutter === "number") {
    styles.padding = `${space[gutter] / 2}px`
    styles.margin = `-${space[gutter] / 2}px`
  } else if (typeof gutter === "object") {
    styles.padding = {}
    styles.margin = {}
    Object.keys(gutter).forEach(breakpoint => {
      if (gutter[breakpoint]) {
        styles.padding[breakpoint] = `${space[gutter[breakpoint]] / 2}px`
        styles.margin[breakpoint] = `-${space[gutter[breakpoint]] / 2}px`
      }
    })
  }

  // Width of child items based on columns
  if (typeof columns === "number") {
    styles.width = `${100 / columns}%`
  } else if (columns === "auto") {
    styles.width = "auto"
  } else if (typeof columns === "object") {
    styles.width = {}
    Object.keys(columns).forEach(breakpoint => {
      if (columns[breakpoint]) {
        if (columns[breakpoint] === "auto") {
          styles.width[breakpoint] = "auto"
        } else {
          styles.width[breakpoint] = `${100 / columns[breakpoint]}%`
        }
      }
    })
  }

  let justified =
    align === "left"
      ? "flex-start"
      : align === "right"
      ? "space-between"
      : "center"

  // TODO: Use something other than index for the keys
  return (
    <Flex
      flexWrap="wrap"
      m={styles.margin}
      justifyContent={justified}
      {...rest}
    >
      {children.map((child, i) => {
        if (child) {
          return (
            <Box
              display={displayChild && displayChild}
              p={styles.padding}
              width={styles.width}
              height="auto"
              key={i}
            >
              {child.props.height
                ? child
                : cloneElement(child, { height: "100%" })}
            </Box>
          )
        } else return null
      })}
    </Flex>
  )
}

FlexGrid.defaultProps = {
  align: "left",
}

FlexGrid.strapiProps = {
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.objectOf(PropTypes.number),
  ]),
  gutter: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.objectOf(PropTypes.number),
  ]),
  align: PropTypes.oneOf(["left", "center"]).isRequired,
}

FlexGrid.propTypes = {
  children: PropTypes.node.isRequired,
  ...FlexGrid.strapiProps,
}

export default FlexGrid

export const query = graphql`
  fragment FlexGrid on ContentfulFlexGrid {
    columns {
      _
      sm
      md
      lg
    }
    gutter {
      _
      sm
      md
      lg
    }
    #align
  }
`
