import React, { useState, useEffect } from "react"
import styled from "styled-components"

import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import { Label, Paragraph } from "components/atoms/Typography"
import Button from "components/atoms/Button"

const Input = styled.input`
  position: relative;
  z-index: 1;
  right: -30px;
  border-radius: 20px;
  background-color: #f0f2fa;
  height: 40px;
  //width: 285px;
  width: ${props => props.width || "285px"};
  max-width: 100%;
  border: none;
  outline: none;
  border-color: transparent;
  padding: 0 20px;
`

const Form = ({ buttonColor, ...rest }) => {
  const [widthProp, setWidthProp] = useState(null)

  useEffect(() => {
    if (window.innerWidth < 425) {
      setWidthProp("100%")
    } else {
      setWidthProp(null)
    }
  }, [])

  return (
    <form
      action="https://jump.rbadvisors.com/l/746533/2021-06-14/j18cb"
      method="post"
    >
      <Flex {...rest}>
        <Input
          required
          name="email"
          type="email"
          placeholder="Enter email..."
          width={widthProp}
        />
        <Box style={{ position: "absolute", left: "-9999px", top: "-9999px" }}>
          <label for="pardot_extra_field">Comments</label>
          <input
            type="text"
            id="pardot_extra_field"
            name="pardot_extra_field"
          />
        </Box>
        <Button
          buttonColor={buttonColor || "secondary"}
          width={{ _: "110px", sm: "136px" }}
          position="relative"
          zIndex={2}
          height="40px"
          variant="filled"
          onClick={() => console.log("subscribe")}
          type="submit"
        >
          Subscribe
        </Button>
      </Flex>
    </form>
  )
}

const NewsletterSignup = ({ title, description, card, ...rest }) => {
  if (card) {
    return (
      <Flex
        py={7}
        alignItems="center"
        bg="white"
        flexDirection="column"
        boxShadow="md"
      >
        <Paragraph mb={4}>{title}</Paragraph>
        <Form buttonColor="primary" ml="-30px" />
      </Flex>
    )
  }
  return (
    <Flex
      flexDirection={{ _: "column", md: "row" }}
      alignItems={{ _: "flex-start", md: "center" }}
      justifyContent="space-between"
      {...rest}
    >
      <Box width={{ _: "100%", md: "50%" }} maxWidth="480px">
        <Label color="newsletterLabel">{title}</Label>
        <Paragraph mt={3}>{description}</Paragraph>
      </Box>
      <Form mt={{ _: 5, md: 0 }} ml={{ _: "-30px", md: 0 }} />
    </Flex>
  )
}

export default NewsletterSignup
