import React, { useContext } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import styled from "styled-components"
import { ThemeContext } from "styled-components"

import ThemeSwitcher from "components/utils/ThemeSwitcher"
import Image from "components/utils/Image"

import Box from "components/atoms/Box"

// For some reason desktop section breaks don't work on Firefox
const StyledSection = styled(Box)`
  overflow: ${props => props.overflow || "hidden"};
`

const Section = props => {
  const {
    children,
    theme,
    backgroundImageMobile,
    backgroundImageDesktop,
    overlayOpacity,
    overflow,
    bg = 0,
    paddingTop,
    paddingBottom,
    ...rest
  } = props
  const background = `background.${bg}`
  const { breakpoints } = useContext(ThemeContext)

  let backgroundSources
  if (backgroundImageMobile && backgroundImageDesktop) {
    const breakpointTablet = parseInt(breakpoints.md)
    backgroundSources = [
      {
        ...backgroundImageMobile,
        ...backgroundImageMobile?.fluid,
        media: `(max-width: ${breakpointTablet}px)`,
      },
      {
        ...backgroundImageDesktop,
        ...backgroundImageDesktop?.fluid,
        media: `(min-width: ${breakpointTablet + 1}px)`,
      },
    ]
  }

  let image
  if (backgroundSources) {
    image = (
      <Image
        fluid={backgroundSources}
        alternativeText={backgroundImageDesktop.alternativeText}
        imgSources={backgroundSources}
        style={{ position: "static" }}
      />
    )
  } else if (backgroundImageDesktop) {
    image = <Image {...backgroundImageDesktop} style={{ position: "static" }} />
  } else if (backgroundImageMobile) {
    image = <Image {...backgroundImageMobile} style={{ position: "static" }} />
  }

  let sectionProps = {}

  return (
    <ThemeSwitcher theme={theme}>
      <StyledSection
        as="section"
        overflow={overflow}
        position="relative"
        bg={image ? null : background}
        {...sectionProps}
        {...rest}
      >
        {image && (
          <>
            <Box
              position="absolute"
              zIndex={-1}
              top="0"
              right="0"
              bottom="0"
              left="0"
            >
              {image}
            </Box>
            <Box
              position="absolute"
              zIndex={-1}
              top="0"
              right="0"
              bottom="0"
              left="0"
              bg={background}
              opacity={overlayOpacity || 0}
            />
          </>
        )}
        {children}
      </StyledSection>
    </ThemeSwitcher>
  )
}

Section.defaultProps = {
  theme: "default",
}

Section.strapiProps = {
  theme: PropTypes.oneOf(["default", "light", "dark", "highlight"]),
  bg: PropTypes.number,
  backgroundImageMobile: PropTypes.object,
  backgroundImageDesktop: PropTypes.object,
  overlayOpacity: PropTypes.number,
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.color,
  ...Section.strapiProps,
}

export default Section

export const query = graphql`
  fragment Section on ContentfulSectionStyling {
    bg
    theme
    backgroundImageMobile {
      fluid(quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    backgroundImageDesktop {
      fluid(quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    paddingTop
    paddingBottom
  }
`
