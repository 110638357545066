import React from "react"
import { graphql } from "gatsby"
import { format } from "date-fns"
import styled from "styled-components"

import Image from "components/utils/Image"
import NewsletterSignup from "components/molecules/NewsletterSignup"
import ThemeSwitcher from "components/utils/ThemeSwitcher"

import Reveal from "components/utils/Reveal"
import Link from "components/utils/Link"

import ArticlePreview from "components/molecules/ArticlePreview"

import Section from "components/atoms/Section"
import StandardPadding from "components/atoms/StandardPadding"
import Button from "components/atoms/Button"
import Flex from "components/atoms/Flex"
import FlexGrid from "components/atoms/FlexGrid"
import Box from "components/atoms/Box"
import { Label, Title, Paragraph } from "components/atoms/Typography"

const StyledBox = styled(Box)`
  transition: opacity 0.2s cubic-bezier(0.17, 0.67, 0.62, 0.88);
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`

const RecentArticles = ({
  sectionStyling,
  label,
  centerLabel,
  button,
  articles,
  includeNewsletterSignup,
  newsletterSignupTitle,
  newsletterSignupDescription,
  isFirstSection,
  title,
  titleVariant,
  newsletterSignupPosition,
}) => {
  // console.log("newsletterSignupPosition: ", newsletterSignupPosition)
  return (
    <Section
      {...sectionStyling}
      mt={isFirstSection && "-100px"}
      pt={isFirstSection && "100px"}
    >
      <StandardPadding
        pt={sectionStyling?.paddingTop}
        pb={sectionStyling?.paddingBottom}
      >
        {newsletterSignupPosition === "top" ? (
          includeNewsletterSignup && (
            <Reveal>
              <NewsletterSignup
                mb={10}
                title={newsletterSignupTitle}
                description={newsletterSignupDescription}
              />
            </Reveal>
          )
        ) : (
          <></>
        )}
        <Flex justifyContent={centerLabel ? "center" : "space-between"} mb={0}>
          <Reveal>
            <Label variant={"h3"}>{label.toUpperCase()}</Label>
          </Reveal>
          {button && (
            <Reveal>
              <Button {...button} />
            </Reveal>
          )}
        </Flex>
        <Flex justifyContent={centerLabel ? "center" : "space-between"} mb={6}>
          <Reveal>
            <Title
              variant={
                titleVariant === "h1" || titleVariant === "h2"
                  ? titleVariant
                  : "h2"
              }
            >
              {title}
            </Title>
          </Reveal>
        </Flex>
        <ThemeSwitcher theme="light">
          <Reveal>
            <FlexGrid columns={{ _: 1, sm: 2, lg: 3 }} gutter={5}>
              {articles.map((article, idx) => {
                return (
                  <ArticlePreview
                    {...article}
                    variant={"whiteCard-2"}
                    contentType={article.__typename}
                  />
                )
                // <Link
                //   style={{
                //     height: "100%",
                //     display: "flex",
                //     alignItems: "stretch",
                //   }}
                //   to={article}
                // >
                //   <StyledBox
                //     width="100%"
                //     maxWidth="380px"
                //     mx="auto"
                //     key={idx}
                //     bg={"white"}
                //     boxShadow={"lg"}
                //   >
                //     <Box position="relative" width="100%" height={0} pb="60%">
                //       <Box
                //         position="absolute"
                //         top={0}
                //         left={0}
                //         right={0}
                //         bottom={0}
                //       >
                //         <Image style={{ height: "100%" }} {...article.image} />
                //       </Box>
                //     </Box>
                //     <Box p={4}>
                //       <Label color={"primary"} mb={2}>
                //         {article.label}
                //       </Label>
                //       <Title variant={"h3"}>{article.title}</Title>
                //       <Paragraph
                //         fontWeight="semibold"
                //         pt={6}
                //         pb={2}
                //         fontSize={"10px"}
                //       >
                //         {format(new Date(article.date), "MMM dd, yyyy")}
                //       </Paragraph>
                //     </Box>
                //   </StyledBox>
                // </Link>
              })}
            </FlexGrid>
          </Reveal>
        </ThemeSwitcher>
        {newsletterSignupPosition === "bottom" ? (
          includeNewsletterSignup && (
            <Reveal>
              <NewsletterSignup
                mt={8}
                title={newsletterSignupTitle}
                description={newsletterSignupDescription}
              />
            </Reveal>
          )
        ) : newsletterSignupPosition === null ? (
          includeNewsletterSignup && (
            <Reveal>
              <NewsletterSignup
                mt={8}
                title={newsletterSignupTitle}
                description={newsletterSignupDescription}
              />
            </Reveal>
          )
        ) : (
          <></>
        )}
      </StandardPadding>
    </Section>
  )
}

export default RecentArticles

export const query = graphql`
  fragment RecentArticles on ContentfulArticlePreview {
    sectionStyling {
      ...Section
    }
    label
    title
    titleVariant
    button {
      ...Button
    }
    articles {
      ... on ContentfulArticleExternalLink {
        __typename
        date
        description
        label
        title
        href
        image {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      ... on ContentfulArticle {
        __typename
        title
        date
        slug
        location
        label
        image {
          description
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    includeNewsletterSignup
    newsletterSignupTitle
    newsletterSignupDescription
    newsletterSignupPosition
  }
`
