import React, { useState, useEffect } from "react"
import { format } from "date-fns"
import "@reach/dialog/styles.css"
import styled from "styled-components"

import Reveal from "components/utils/Reveal"
import Link from "components/utils/Link"
import Image from "components/utils/Image"

import ProportionalBox from "components/atoms/ProportionalBox"
import { Title, Label, Paragraph } from "components/atoms/Typography"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import VideoDialog from "components/molecules/VideoDialog"

import Card from "components/atoms/Card"

import PlayIcon from "components/icons/blue-play-video-icon.svg"

const StyledTitle = styled(Title)`
  max-width: 300px;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledCard = styled(Card)`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const StyledBox = styled(Box)`
  cursor: pointer;
  .play-icon {
    transition: all 0.4s cubic-bezier(0.17, 0.67, 0.43, 0.96);
  }
  transition: all 0.2s cubic-bezier(0.17, 0.67, 0.43, 0.96);
  &:hover {
    opacity: 0.7;
    .play-icon {
      width: 50px;
      height: 50px;
      transform: translateY(-3px);
      transform: translateX(-3px);
    }
  }
`

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const ConditionalLink = ({
  disclosureText,
  location,
  slug,
  href,
  children,
}) => {
  if (href !== null && href !== undefined) {
    return (
      <Link href={href} disclosureText={disclosureText}>
        {children}
      </Link>
    )
  }
  if (slug !== null && slug !== undefined) {
    return <Link to={`/${location}/${slug}`}>{children}</Link>
  }
}

const ArticlePreview = ({
  title,
  image,
  slug,
  href,
  location,
  date,
  source,
  articleSource,
  label,
  html,
  foxBusinessVideoId,
  description,
  variant,
  contentType,
  __typename,
  ...rest
}) => {
  let actualLabel = label
  if (__typename === "ContentfulVideo") {
    actualLabel = "Video"
  }
  const [showDialog, setShowDialog] = useState(null)

  if (variant === "white") {
    variant = "whitecard"
  }
  //get date with timezone offset
  let tzDate = new Date(date)
  tzDate = new Date(tzDate.getTime() + tzDate.getTimezoneOffset() * 60000)

  const disclosureText = rest?.section?.disclosureText

  return (
    <>
      <ConditionalWrapper
        condition={slug || href}
        wrapper={children => (
          <ConditionalLink
            disclosureText={disclosureText}
            href={href}
            slug={slug}
            location={location}
          >
            {children}
          </ConditionalLink>
        )}
      >
        <StyledBox
          bg={
            variant === "dark"
              ? "#4B67D3"
              : variant === "whiteCard" || variant === "whiteCard-2"
              ? "white"
              : "transparent"
          }
          // border={"lightblue 1px solid"}
          position={"relative"}
          maxWidth={25}
          mx="auto"
          height="100%"
          onClick={() => {
            if (__typename === "ContentfulVideo") {
              setShowDialog(true)
            }
          }}
          boxShadow={
            variant === "whiteCard" || (variant === "whiteCard-2" && "md")
          }
        >
          <ProportionalBox mb={4} ratio={0.54} position={"relative"}>
            <Flex
              position={"absolute"}
              //bg={"rgba(75, 103, 211, 0.1)"}
              width={"100%"}
              height={"100%"}
              zIndex={30}
              justifyContent={"center"}
              alignItems={"center"}
              // border={"red 1px solid"}
              maxHeight={"300px"}
            >
              {__typename === "ContentfulVideo" && (
                <Box width={"44px"} height={"44px"}>
                  <PlayIcon className={"play-icon"} />
                </Box>
              )}
            </Flex>
            <Image {...image} style={{ height: "100%" }} />
          </ProportionalBox>
          {variant === "dark" && (
            <Box px={5}>
              <Paragraph fontSize={"10px"} color={"white"} mb={3}>
                {(source ? source + " | " : "") +
                  format(tzDate, "MMM dd, yyyy")}
              </Paragraph>
              <StyledTitle color={"white"} variant="h3" mb={3}>
                {title}
              </StyledTitle>
              <Box minHeight={"70px"}>
                <Paragraph color={"white"} fontSize={"11px"} mb={3}>
                  {description}
                </Paragraph>
              </Box>
              <Label color={"white"} pb={4}>
                {actualLabel}
              </Label>
            </Box>
          )}
          {variant === "whiteCard" && (
            <Box px={5}>
              <Label color={"lightText"} mb={2}>
                {actualLabel}
              </Label>
              <StyledTitle color={"secondary"} variant="h3" mb={3}>
                {title}
              </StyledTitle>
              <Paragraph fontSize={"10px"} color={"secondary"} pb={4}>
                {format(tzDate, "MMM dd, yyyy")}
              </Paragraph>
            </Box>
          )}
          {variant === "whiteCard-2" && (
            <Box p={4}>
              <Label color={"primary"} mb={2}>
                {actualLabel}
              </Label>
              <Box mb={7}>
                <Title variant={"h3"}>{title}</Title>
              </Box>
              <Box
                // border={"lightgreen 1px solid"}
                position={"absolute"}
                bottom={"15px"}
                left={"15px"}
              >
                <Paragraph
                  fontWeight="semibold"
                  pt={6}
                  pb={2}
                  fontSize={"10px"}
                >
                  {format(tzDate, "MMM dd, yyyy")}
                </Paragraph>
              </Box>
            </Box>
          )}
          {variant !== "dark" &&
            variant !== "whiteCard" &&
            variant !== "whiteCard-2" && (
              <Box>
                <Label color={"primary"} mb={2}>
                  {actualLabel}
                </Label>
                <ConditionalWrapper
                  condition={slug}
                  wrapper={children => (
                    <Link to={`/${location}/${slug}`}>{children}</Link>
                  )}
                >
                  <Title color={"text"} variant="h3" mb={3}>
                    {title}
                  </Title>
                </ConditionalWrapper>
                <Paragraph color={"primary"} fontSize={"11px"} mb={3}>
                  {description}
                </Paragraph>
                <Paragraph color={"primary"} mb={4} fontSize={"12px"}>
                  {(source && source) || (articleSource && articleSource)} |{" "}
                  {format(tzDate, "MMM dd, yyyy")}
                </Paragraph>
              </Box>
            )}
        </StyledBox>
      </ConditionalWrapper>
      {showDialog && (
        <VideoDialog
          close={() => setShowDialog(null)}
          show={showDialog}
          html={html}
          title={title}
          foxBusinessVideoId={foxBusinessVideoId}
        />
      )}
    </>
  )
}

export default ArticlePreview
