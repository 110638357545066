import React, { useContext } from "react"
import ReactReveal from "react-reveal/Reveal"
import styled, { ThemeContext } from "styled-components"
import { layout } from "styled-system"
import { animation } from "lib/styled-system/system"
import { fade, fadeUp } from "lib/styled-system/reveal-keyframes"

// Used to apply styles to the .react-reveal div
// https://github.com/styled-components/styled-components/issues/2113
const RevealWrapper = styled.div.attrs(props => ({
  flexGrow: props.flexGrow || 0,
}))`
  flex-grow: ${props => props.flexGrow};
  ${layout}
  & .react-reveal {
    visibility: hidden;
    animation-fill-mode: forwards;
    ${animation}
  }
  & .react-reveal.none {
    visibility: visible;
  }
  & .react-reveal.fade {
    visibility: visible;
    animation-name: ${fade};
  }
  & .react-reveal.fade-up {
    visibility: visible;
    animation-name: ${fadeUp};
  }
`

/*
 * Uses the reveal effect defined in the theme
 */
const Reveal = ({ children, height, flexGrow, ...rest }) => {
  // Get reveal effect and durations from theme
  const { reveal, durations } = useContext(ThemeContext)

  // Apply defaults if effect, duration, or timingFunction isn't defined in theme
  const revealEffect = reveal?.effect || "none"
  const revealDuration = durations[reveal?.duration] || durations["md"]
  const revealTimingFunction = reveal?.timingFunction || "ease"

  return (
    <RevealWrapper
      animationTimingFunction={revealTimingFunction}
      height={height}
      flexGrow={flexGrow}
    >
      <ReactReveal effect={revealEffect} duration={revealDuration} {...rest}>
        {children}
      </ReactReveal>
    </RevealWrapper>
  )
}

export default Reveal
